#tabMainProduct {
    .react-tabs__tab-list {
      // margin: 0 auto;
      width: fit-content;
      display: flex;
      align-items: center;
      border: 0 !important;
      padding: 0;
      @media all and (max-width: 500px) {
        .react-tabs__tab {
          font-size: 14px !important;
          &:nth-child(2) {
            margin: 0 10px !important;
            padding: 0 5px;
          }
          &.react-tabs__tab--selected {
          }
          &.react-tabs__tab:focus {
          }
        }
      }
      .react-tabs__tab {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #a1a1aa;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        width: fit-content;
        white-space: nowrap;
        cursor: pointer;
        @media all and (max-width: 600px) {
          padding: 0 5px;
        }
        &:nth-child(2) {
          margin: 0 40px;
        }
        &.react-tabs__tab--selected {
          background: #f4f4f5;
          border-radius: 5px;
          border: 0;
          color: #18181b;
        }
        &.react-tabs__tab:focus {
          background: #f4f4f5;
          border-radius: 5px;
          border: 0;
          color: #18181b;
        }
      }
    }
  }