.activeTabLeft {
  div {
    color: #18181b !important;
  }
}
.infoCard {
  background: #eff6ff;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 15px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #52525b;
  }
}
#tabMain {
  .react-tabs__tab-list {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    align-items: center;
    border: 0 !important;
    padding: 0;
    @media all and (max-width: 500px) {
      .react-tabs__tab {
        font-size: 14px !important;
        &:nth-child(2) {
          margin: 0 10px !important;
          padding: 0 5px;
        }
        &.react-tabs__tab--selected {
        }
        &.react-tabs__tab:focus {
        }
      }
    }
    .react-tabs__tab {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #a1a1aa;
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      width: fit-content;
      white-space: nowrap;
      cursor: pointer;
      @media all and (max-width: 600px) {
        padding: 0 5px;
      }
      &:nth-child(2) {
        margin: 0 40px;
      }
      &:nth-child(4) {
        margin-left: 40px;
      }
      &.react-tabs__tab--selected {
        background: #f4f4f5;
        border-radius: 5px;
        border: 0;
        color: #18181b;
      }
      &.react-tabs__tab:focus {
        background: #f4f4f5;
        border-radius: 5px;
        border: 0;
        color: #18181b;
      }
    }
  }
}

#tabPanel {
  .tabVertical {
    .react-tabs__tab-list {
      display: flex;
      border: 0;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;

      .react-tabs__tab {
        border: 0;
        outline: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 18px;
        cursor: pointer;
        color: #a1a1aa;
        text-transform: capitalize;

        &.react-tabs__tab--selected {
          color: #18181b;
          .p1,
          .p2 {
            color: #18181b;
          }
        }
      }
      .react-tabs__tab:focus {
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
  }
}

.olist {
  margin-bottom: 16px;
  .arrow {
    margin-right: 5px;
    margin-left: 10px;
  }
  display: flex;
  align-items: center;
  .p1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    color: #a1a1aa;
    // text-transform: capitalize;
    margin-right: 2px;
    margin-bottom: 16px;
  }
}
.p0 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: #a1a1aa;
  // text-transform: capitalize;
  margin-right: 2px;
}
.p1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: #a1a1aa;
  // text-transform: capitalize;
  margin-right: 2px;
  // margin-bottom: 16px;
}
.p2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: #a1a1aa;
  // text-transform: capitalize;
  margin-left: 25px;
  margin-bottom: 16px;
}

.olist2 {
  margin-bottom: 16px;
  margin-left: 20px;
  .arrow {
    margin-right: 5px;
    margin-left: 10px;
  }
  display: flex;
  align-items: center;

  .p1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    color: #a1a1aa;
    // text-transform: capitalize;
    margin-right: 2px;
    // margin-bottom: 16px;
  }
}
.p2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: #a1a1aa;
  // text-transform: capitalize;
  margin-left: 50px;
}

#settings {
  .c-disabled {
    cursor: not-allowed;
  }
  small {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #a1a1aa;
    margin-right: 10px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-decoration-line: underline;
      color: #3b82f6;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  * {
    font-family: "Inter";
  }
  .col-1 {
    width: 6%;
    margin-bottom: 10px;
  }
  .col-2 {
    width: 14%;
    margin-bottom: 10px;
  }
  .col-3 {
    width: 22%;
    margin-bottom: 10px;
  }
  .col-4 {
    width: 30%;
    margin-bottom: 10px;
  }
  .col-6 {
    width: 48%;
    margin-bottom: 10px;
  }
  .col-7 {
    width: 56%;
    margin-bottom: 10px;
  }
  .col-8 {
    width: 64%;
    margin-bottom: 10px;
  }
  .col-9 {
    width: 72%;
    margin-bottom: 10px;
  }
  .col-10 {
    width: 81%;
    margin-bottom: 10px;
  }
  .col-11 {
    width: 89%;
    margin-bottom: 10px;
  }
  .col-12 {
    width: 100%;
    margin-bottom: 10px;
  }
  .d-flex {
    display: flex;
    align-items: center;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .a-i.center {
    align-items: center;
  }
  .j-c-between {
    justify-content: space-between;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .h-100 {
    height: 100%;
  }
  .max-w-75px {
    max-width: 75px !important;
  }
  .max-w-100 {
    max-width: 100% !important;
  }
  .min-w-100 {
    min-width: 100% !important;
  }
  .ms-2 {
    margin-left: 10px;
  }
  .me-2 {
    margin-right: 10px;
  }

  @media all and (max-width: 500px) {
    .col-3,
    .col-9,
    .col-12,
    .col-8 {
      width: 100%;
      margin-bottom: 10px;
    }
    .row {
      width: 100%;
    }
  }

  @media all and (max-width: 768px) {
    .col-4 {
      width: 100%;
      margin-bottom: 10px;
    }
    .col-6 {
      width: 100%;
      margin-bottom: 10px;
    }
    .col-12 {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .saveBtn {
    background: #154b49;
    border: 1px solid #154b49;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    cursor: pointer;
  }
  .input {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #18181b;
    }
    .inputBox {
      position: relative;
      .displayText {
        background: #f4f4f5;
        margin: 4px 0;
        padding: 12px 9px;
        width: 100%;
        height: 48px;
        background: #f4f4f5;
        border-radius: 5px;
        outline: none;
        border: none;
        display: inline-block;
      }
      input {
        margin: 4px 0;
        padding: 12px 9px;
        width: 100%;
        height: 48px;
        background: #f4f4f5;
        border-radius: 5px;
        outline: none;
        border: none;
        font-size: 16px;
        line-height: 150%;
        color: #52525b;
      }
      input.foucsIn {
        background: #d1d1d3 !important;
      }
      .edit {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
      }
      a {
        cursor: pointer;
      }
    }

    span.error {
      color: red;
      font-size: 12px;
      font-weight: 300;
      display: inline-block;
    }
  }
  .checkBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #18181b;
    }
    input {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
  .select {
    // margin-right: 20px;

    .inputBox {
      position: relative;
      max-width: 150px;
      width: 100%;
      // &::after {
      //   content: "";
      //   width: 11px;
      //   height: 7px;
      //   background-image: url("/settings/arrowdown.svg");
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   display: inline-block;
      //   background-size: contain;
      //   position: absolute;
      //   right: 13px;
      //   top: 22px;
      // }
    }
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #18181b;
    }
    small {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #a1a1aa;
      margin-right: 10px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3b82f6;
        margin-right: 10px;
      }
    }
    span.error {
      color: red;
      font-size: 12px;
      font-weight: 300;
      display: inline-block;
    }
    select {
      cursor: pointer;
      margin: 4px 0;
      // text-align: center;
      box-sizing: border-box;
      appearance: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      background: #f4f4f5;
      border-radius: 5px;
      line-height: 150%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #52525b;
      outline: none;
      padding: 0 15px;
      border: 0;
      width: 100%;
      appearance: none;
      background-image: url("/settings/arrowdown.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    }
  }
  .textarea {
    height: 100%;
    .inputBox {
      position: relative;
      width: 100%;
      height: 100%;
    }
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #18181b;
    }
    small {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #a1a1aa;
      margin-right: 10px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3b82f6;
        margin-right: 10px;
      }
    }
    span.error {
      color: red;
      font-size: 12px;
      font-weight: 300;
      display: inline-block;
    }
    textarea {
      cursor: pointer;
      // text-align: center;
      box-sizing: border-box;
      min-height: 250px;
      height: 100%;
      background: #f4f4f5;
      border-radius: 5px;
      line-height: 150%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #52525b;
      outline: none;
      padding: 18px;
      border: 0;
      width: 100%;
      margin-top: 5px;
      &.foucsIn {
        background: #d1d1d3 !important;
      }
    }
    .edit {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }

  .draftJsEmojiPlugin__emojiSelectButton__3sPol {
    border: 0 !important;
    background-color: inherit;
    cursor: pointer;
    width: fit-content !important;
  }
  .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    border: 0 !important;
    background-color: inherit;
    cursor: pointer;
    width: fit-content !important;
  }
  .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
    left: -12px;
    bottom: 41px;
  }
  .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
    width: 300px;
    height: 12em;
  }
}

#addNewTemplate {
  .draftJsEmojiPlugin__emojiSelectButton__3sPol {
    border: 0 !important;
    background-color: inherit;
    cursor: pointer;
    width: fit-content !important;
  }
  .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    border: 0 !important;
    background-color: inherit;
    cursor: pointer;
    width: fit-content !important;
  }
  .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
    left: -12px;
    bottom: 41px;
  }
  .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
    width: 400px;
    height: 8em;
  }
}

#templatesList {
  .react-tabs__tab-list {
    display: flex;
    border: 0;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
    .react-tabs__tab {
      border: 0;
      outline: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      margin-right: 5px;
      padding: 5px 8px;

      cursor: pointer;
      color: #a1a1aa;

      &.react-tabs__tab--selected {
        color: #18181b;
        padding: 5px 8px;

        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        background: #f4f4f5;
        border-radius: 5px;
      }
    }
    .react-tabs__tab:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
}
.ReactModal__Overlay {
  z-index: 6666 !important;
  overflow-y: auto;
  // margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
@media all and (max-width: 1300px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    transform: translate(-50%, -30%) !important;
  }
}
@media all and (max-width: 820px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 95% !important;
    min-width: 95% !important;
    transform: translate(-50%, -40%) !important;
  }

  .drawer-content-wrapper {
    width: 98% !important;
  }
}
